import { render, staticRenderFns } from "./form-draggable-section.vue?vue&type=template&id=49c35632&scoped=true"
import script from "./form-draggable-section.vue?vue&type=script&lang=js"
export * from "./form-draggable-section.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49c35632",
  null
  
)

export default component.exports